$build: 1742981483150;
$cdn: "/joxi";
$cdnProject: undefined;





.preloader-index{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:#555555;
  z-index:3000;
  &.loaded{ display:none;}
}

.preloader{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.5);
  z-index: 2000;
  border-radius: 5px;
  &.z500{ z-index: 500;}
  &__ico{
    width:100px;
  }
  &__css{
    width:100px;
    height:70px;
    //border:solid 1px red;
    position:relative;
    display:flex;
    justify-content: space-between;
    align-items: center;
    transform: rotate(-25deg);
    margin:0 auto;
    //&-wrap{ border:solid 1px red; width:120px;}

    &-i{
      width:18px;
      height:100%;
      border-radius:12px;

      &_1{
        background:#97fff8;
        animation:preloaderMove1 1s linear infinite;
        height:100%;
        transform: translate( 0 , -20px)  ;

      }
      &_2{
        background:#d5ffb9;
        height:70%;
        animation:preloaderMove2 0.5s linear infinite;
      }
      &_3{
        background:#ffff9f;
        height:100%;
        transform: translate( 0 , 20px)  ;
        animation:preloaderMove3 1s linear infinite;
        //animation-direction:reverse;
      }
    }
    &-v{
      background:#6443b3;
    }
  }
}
@keyframes preloaderMove1 {
  25% { height:30%; transform: translate( 0 , -40px)  ; }
  50% { height:100%; transform: translate( 0 , -20px)  ;}
  75% { height:30%; transform: translate( 0 , 0px) ;}
}
@keyframes preloaderMove2 {
  50%{ height:30%;}
}
@keyframes preloaderMove3 {
  25% { height:30%; transform: translate( 0 , 40px)  ; }
  50% { height:100%; transform: translate( 0 , 20px)  ;}
  75% { height:30%; transform: translate( 0 , 0px) ;}
}




.preloader-page{
  padding:50px 0;
  display:flex;
  align-items: center;
  justify-content: center;
  width:100%;
  &__ico{
    width:100px;
  }
}

.preloader-game-box{
//  position:absolute;
//  top:0;
//  left:0;
//  width:100%;
//  height:100%;
//  z-index: 50;
//  background: rgba( 255,255,255,0.5);
  display:flex !important;
  align-items: center ;
  justify-content: center;
  overflow:hidden;
  .game-hall__games &,
  .game-page-demo &,
  .game-box-home &{
    width:100% !important;
    margin:0 !important;
    padding:20px 0;
    flex-grow: 5;
  }
}