.preloader-index {
  z-index: 3000;
  background: #555;
  position: fixed;
  inset: 0;
}

.preloader-index.loaded {
  display: none;
}

.preloader {
  z-index: 2000;
  background: #00000080;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.preloader.z500 {
  z-index: 500;
}

.preloader__ico {
  width: 100px;
}

.preloader__css {
  justify-content: space-between;
  align-items: center;
  width: 100px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  position: relative;
  transform: rotate(-25deg);
}

.preloader__css-i {
  border-radius: 12px;
  width: 18px;
  height: 100%;
}

.preloader__css-i_1 {
  background: #97fff8;
  height: 100%;
  animation: 1s linear infinite preloaderMove1;
  transform: translate(0, -20px);
}

.preloader__css-i_2 {
  background: #d5ffb9;
  height: 70%;
  animation: .5s linear infinite preloaderMove2;
}

.preloader__css-i_3 {
  background: #ffff9f;
  height: 100%;
  animation: 1s linear infinite preloaderMove3;
  transform: translate(0, 20px);
}

.preloader__css-v {
  background: #6443b3;
}

@keyframes preloaderMove1 {
  25% {
    height: 30%;
    transform: translate(0, -40px);
  }

  50% {
    height: 100%;
    transform: translate(0, -20px);
  }

  75% {
    height: 30%;
    transform: translate(0);
  }
}

@keyframes preloaderMove2 {
  50% {
    height: 30%;
  }
}

@keyframes preloaderMove3 {
  25% {
    height: 30%;
    transform: translate(0, 40px);
  }

  50% {
    height: 100%;
    transform: translate(0, 20px);
  }

  75% {
    height: 30%;
    transform: translate(0);
  }
}

.preloader-page {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px 0;
  display: flex;
}

.preloader-page__ico {
  width: 100px;
}

.preloader-game-box {
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: flex !important;
}

.game-hall__games .preloader-game-box, .game-page-demo .preloader-game-box, .game-box-home .preloader-game-box {
  flex-grow: 5;
  padding: 20px 0;
  width: 100% !important;
  margin: 0 !important;
}
/*# sourceMappingURL=index.dacbe630.css.map */
